<template>
	<div class="single-reality" :id="'reality-' + id">
		<router-link :to="{name: 'Detail', params: {slug: slug}}">
			<div class="image-wrapper">
				<div class="background-image">
					<img :src="photo" :alt="title">
				</div>
				
				<div class="tags-wrapper">
					<div class="left">
						<div v-if="tagTop" class="tag tag-red">Top</div>
						<div v-if="tagNew" class="tag tag-green">Novinka</div>
					</div>
					
					<div class="right">
						<div class="tag tag-yellow">{{ type }}</div>
					</div>
				</div>
				
				<div class="top-params">
					<div class="location-wrapper">
						<div class="icon">
							<img src="../../assets/icons/adresa.png" alt="adresa">
						</div>
						<div class="location">{{ location }}</div>
					</div>
					
					<div class="image-count">
						<div class="icon">
							<img src="../../assets/icons/fotky.png" alt="fotky">
						</div>
						<div class="count">{{ photoCount }}</div>
					</div>
				</div>
			</div>
			
			<div class="reality-content">
				<h3 class="reality-title">{{ title }}</h3>
				
				<div class="params">
					<div class="price">{{ formatPrice }} <span>s DPH</span></div>
					<div class="price-squared">{{ formatSquaredPrice }}</div>
				</div>
				
				<div class="bottom-params">
					<div class="type-wrapper">
						<div class="icon">
							<img src="../../assets/icons/typ_nehnutelnosti.png" alt="typ">
						</div>
						<div class="type">{{ category }}</div>
					</div>
					
					<div class="share" :id="'tooltip-share-' + id" @click.prevent>
						<div class="icon">
							<img src="../../assets/icons/zdielanie.png" alt="zdielat">
						</div>
					</div>
					
					<b-tooltip :target="'tooltip-share-' + id" triggers="hover" variant="dark" placement="topleft" :container="'reality-' + id">
						<div class="social-tooltip">
							<a :href="shareFacebook" target="_blank">
								<div class="icon">
									<i class="fa-brands fa-facebook-f fa-fw"></i>
								</div>
							</a>
						</div>
					</b-tooltip>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
	props: ['premise'],
	methods: {
		share() {
			console.warn('share');
		}
	},
	computed: {
		id: {
			get() {
				return this.premise?.id
			}
		},
		title: {
			get() {
				return `${this.premise?.title}`
			}
		},
		slug: {
			get() {
				return `${this.premise?.slug}`
			}
		},
		photo: {
			get() {
				return this.premise?.photo
			}
		},
		photoCount: {
			get() {
				return `${this.premise?.photo_count}`
			}
		},
		category: {
			get() {
				return `${this.premise?.category?.title}`
			}
		},
		type: {
			get() {
				return `${this.premise?.type?.title}`
			}
		},
		tagTop: {
			get() {
				if (!this.premise?.top_to) return false
				const toDate = this.$moment(this.premise?.top_to).valueOf()
				const currDate = this.$moment(new Date()).valueOf()
				return currDate < toDate
			}
		},
		tagNew: {
			get() {
				if (!this.premise?.new_to) return false
				const toDate = this.$moment(this.premise?.new_to).valueOf()
				const currDate = this.$moment(new Date()).valueOf()
				return currDate < toDate
			}
		},
		location: {
			get() {
				const address = `${this.premise?.address?.street}, ${this.premise?.address?.city}`
				return address
			}
		},
		formatPrice: {
			get() {
				const price = this.premise?.price_with_tax;
				const value = (Math.trunc(Number(price) * 100) / 100).toLocaleString();
				return value + ' €';
			}
		},
		formatSquaredPrice: {
			get() {
				const price = this.premise?.price_per_meter_with_tax;
				// const value = Number(price).toLocaleString('sk-SK');
				const value = (Math.trunc(Number(price) * 100) / 100).toLocaleString();
				return value + '€/m2';
			}
		},
		shareFacebook: {
			get() {
				return `https://www.facebook.com/sharer/sharer.php?u=https://${window.location.host}/detail/${this.premise?.slug}`
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.single-reality::v-deep {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	
	> a {
		text-decoration: none;
		
		&:hover {
			color: unset !important;
			text-decoration: none !important;
		}
	}
	
	.image-wrapper {
		position: relative;
		
		.background-image {
			position: relative;
			width: 100%;
			height: auto;
			aspect-ratio: 16/11;
			
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		
		.tags-wrapper {
			position: absolute;
			top: 5px;
			left: 5px;
			display: flex;
			flex-direction: row;
			width: calc(100% - 10px);
			
			.left, .right {
				display: flex;
				flex-direction: row;
			}
			
			.right {
				margin-left: auto;
			}
			
		}
		
		.top-params {
			position: absolute;
			display: flex;
			flex-direction: row;
			left: 15px;
			bottom: 15px;
			width: calc(100% - 30px);
			text-shadow: 0 0 10px black;
			
			.location-wrapper {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				
				.icon img {
					width: 20px;
				}
				
				.location {
					color: white;
					font-size: 13px;
					font-weight: 200;
					margin-left: 5px;
				}
			}
			
			.image-count {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				margin-left: auto;
				
				.icon img {
					width: 20px;
				}
				
				.count {
					color: white;
					font-size: 13px;
					font-weight: 200;
					margin-left: 8px;
				}
			}
		}
	}
	
	.reality-content {
		background: white;
		padding: 15px 15px;
		
		.reality-title {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			min-height: 74px;
			color: $black;
			font-size: 19px;
			line-height: 1.3;
			font-weight: 600;
		}
		
		.params {
			display: flex;
			flex-direction: row;
			align-items: baseline;
			margin-bottom: 20px;
			
			.price {
				color: $text-blue;
				font-size: 21px;
				font-weight: 600;
				
				span {
					color: $text-light-gray;
					font-size: 13px;
					font-weight: 400;
				}
			}
			
			.price-squared {
				color: $text-light-gray;
				font-size: 13px;
				margin-left: 10px;
			}
		}
		
		.bottom-params {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			border-top: 1px solid #ccc;
			padding-top: 15px;
			
			.type-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				
				.icon img {
					width: 25px;
				}
				
				.type {
					color: $text-light;
					font-size: 13px;
					margin-left: 8px;
				}
			}
			
			.share {
				margin-left: auto;
				
				.icon img {
					width: 22px;
				}
			}
		}
	}
	
}
</style>